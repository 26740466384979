
import Vue from "vue";
import { LocaleMessages } from "vue-i18n";
import {
  exactRule,
  maxLengthRule,
  minLengthRule,
  requiredRule
} from "@/services/helpers/validation-rules";
import { mask } from "vue-the-mask";

export default Vue.extend({
  name: "DashboardSecurity",

  props: {
    user: {
      type: Object,
      required: true
    }
  },

  directives: {
    mask
  },

  data: () => ({
    loaded: false as boolean,
    model: {} as any,
    errorMessages: {} as { [value: string]: Array<string> },
    showPassword: {
      new: false as boolean,
      repeat: false as boolean
    } as { [value: string]: boolean }
  }),

  computed: {
    rules() {
      return {
        required: [
          (v: string): boolean | string | LocaleMessages =>
            !!v || this.$t("global_validation.required")
        ],
        exactRule: exactRule.bind(this),
        requiredRule: requiredRule.bind(this),
        minLengthRule: minLengthRule.bind(this),
        maxLengthRule: maxLengthRule.bind(this),
        identityPassword: (v: string): string | boolean =>
          v === (this.model as any).password || "Пароли не совпадают"
      };
    }
  },

  async mounted() {
    await this.loadData();
  },

  methods: {
    async loadData(): Promise<any> {
      try {
        const response = await this.$API.users().getUser();

        this.model = {
          ...response.data
        };
      } catch (e) {
        await this.$store.dispatch("alerts/showError", "Произошла ошибка");
      }
    },
    async submit(): Promise<any> {
      this.loaded = true;
      try {
        if (
          (this.$refs.form as Vue).validate() &&
          this.model.password === this.model.password_confirmation
        ) {
          const model = { ...this.model };

          await this.$API.users().edit(model.id, model);
          await this.$store.dispatch(
            "alert/showSuccess",
            this.$t("global_alert.successful_create")
          );
        }
      } catch (e) {
        if (e.hasOwnProperty("errors")) {
          this.errorMessages = e.errors;

          setTimeout(() => {
            this.errorMessages = {
              password_confirm: ["Не соответсвует пароль"]
            };
          }, 2000);
        }
        await this.$store.dispatch(
          "alert/showError",
          "Пройзошла ошибка при редактировании."
        );
      }
      this.loaded = false;
    },
    toggleShowPassword(type: string): void {
      this.showPassword[type] = !this.showPassword[type];
    }
  }
});
